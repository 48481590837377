.right-align {
    text-align: end;
}

.alert-success {
    background-color: #dff0d8;
    border-color: #d6e9c6;
}

.alert {
    width: 100%;
    padding: 15px;
    margin-bottom: 20px;
    border: 1px solid transparent;
    border-radius: 4px;
}

.margin-top-16 {
    margin-top: 16px;
}