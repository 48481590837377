.customers-and-partner {
	color: #000000;
	font-family: Lato;
	font-size: 16px;
	line-height: 20px;
}
.account-type-label{
	color: #000000;
	font-family: Lato;
	font-size: 16px;
	font-weight: bold;
	letter-spacing: 0.29px;
	line-height: 19px;
}

.account-type-text {
	color: #000000;
	font-family: Lato;
	font-size: 16px;
	letter-spacing: 0.29px;
	line-height: 19px;
}