.user-info {
	height: 91px;
	width: 329px;
}

.name {
	height: 19px;
	width: 120px;
	color: #000000;
	font-family: Lato;
	font-size: 16px;
	font-weight: bold;
	letter-spacing: -0.05px;
	line-height: 19px;
}

.user-name {
	height: 19px;
	width: 86px;
	color: #000000;
	font-family: Lato;
	font-size: 16px;
	letter-spacing: -0.05px;
	line-height: 19px;
}

.email-address {
	height: 19px;
	width: 120px;
	color: #000000;
	font-family: Lato;
	font-size: 16px;
	font-weight: bold;
	letter-spacing: -0.05px;
	line-height: 19px;
}

.spiderman-webcrawler {
	height: 19px;
	width: 200px;
	color: #000000;
	font-family: Lato;
	font-size: 16px;
	letter-spacing: -0.05px;
	line-height: 19px;
}
.company-name {
	height: 19px;
	width: 120px;
	color: #000000;
	font-family: Lato;
	font-size: 16px;
	font-weight: bold;
	letter-spacing: -0.05px;
	line-height: 19px;
}
.web-crawler-inc {
	height: 19px;
	width: 200px;
	color: #000000;
	font-family: Lato;
	font-size: 16px;
	letter-spacing: -0.05px;
	line-height: 19px;
}
.account-title{
    flex:1
  }
.font-size-header{
    font-size:14px
  }
  @media (max-width: 767px) {
    .header-container{
        flex-direction: column-reverse;    
    }
    .font-size-header{
      font-size: 16px;
      margin-bottom: 0.5em;
	}
  }