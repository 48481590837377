.tooltipColor{
    color: black !important;
    background-color: lightgray !important;
    pointer-events: auto !important;
    width: 500px;
    font-size: 12px !important;
    opacity: 1 !important;
    &.place-top {
    &:after {
    border-top-color: lightgray !important;
    border-top-style: solid !important;
    border-top-width: 6px !important;
    }
    }
    &.place-left {
      &:after {
      border-left-color: lightgray !important;
      border-left-style: solid !important;
      border-left-width: 6px !important;
      }
      } 
      &.place-right {
         &:after {
         border-right-color: lightgray !important;
         border-right-style: solid !important;
         border-right-width: 6px !important;
         }
         } 
         &.place-bottom {
            &:after {
            border-bottom-color: lightgray !important;
            border-bottom-style: solid !important;
            border-bottom-width: 6px !important;
            }
            } 
   }

   .tooltipChangePwdColor{
      color: black !important;
      background-color: lightgray !important;
      pointer-events: auto !important;
      width: 330px;
      font-size: 12px !important;
      opacity: 1 !important;
      &.place-top {
      &:after {
      border-top-color: lightgray !important;
      border-top-style: solid !important;
      border-top-width: 6px !important;
      }
      }
      &.place-left {
        &:after {
        border-left-color: lightgray !important;
        border-left-style: solid !important;
        border-left-width: 6px !important;
        }
        } 
        &.place-right {
           &:after {
           border-right-color: lightgray !important;
           border-right-style: solid !important;
           border-right-width: 6px !important;
           }
           } 
           &.place-bottom {
              &:after {
              border-bottom-color: lightgray !important;
              border-bottom-style: solid !important;
              border-bottom-width: 6px !important;
              }
              } 
     }

  
.pseudolink { 
   color:#039BE5;
   text-decoration:underline; 
   cursor:pointer; 
   }


   /* Media queries for mobiles. Changing width of tooltip  */

   @media screen and (max-width: 767px){
      .tooltipColor{
      width: 60%;
      }
      .tooltipChangePwdColor{
         width:60%;
      }

   }




  
   