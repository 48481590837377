/*encrption agreement css*/

.to-download-encrypte {	
    /* height: 72px;	 */
    /* width: 1118px;	 */
    color: #000000;	
    font-family: Lato;	
    font-size: 16px;	
    line-height: 24px;
  }
  .line-5 {	
    box-sizing: border-box;	
    height: 1px;	
    /* width: 1118px;	 */
    border: 1px solid #979797;
  }
  .the-agreement-you-ha {	
    /* width: 1118px;	 */
    color: #000000;	
    font-family: Lato;	
    font-size: 14px;	
    line-height: 20px;
    text-align: justify;
  }
  .user-det-key{
    /* height: 19px;	 */
    color: #000000;	
    font-family: Lato;	
    font-size: 16px;	
    font-weight: bold;	
    letter-spacing: -0.05px;	
    line-height: 19px;
    text-align: left; 
    margin: 5px;
    flex: 1
  }
  .user-det-val{
    /* height: 19px;	 */
    color: #000000;	
    font-family: Lato;	
    font-size: 16px;	
    letter-spacing: -0.05px;	
    line-height: 19px;
    text-align: left; 
    margin: 5px;
    flex: 6
  }
  .user-det-container{
    display: flex;
    padding: 5px;
    align-items: center;
  }
  @media (max-width: 767px) {
    .user-det-container{
      display: flex;
      margin: 8px 0;
      flex-direction: column;
      align-items: flex-start
    }
    .nav-buttons{
      flex-direction: row
    }
  }
  .oval {
      height: 14px;
      width: 14px;
      background-color: #77B6F7;
  }
  .i-so-certify{
      color: #000000;
      font-family: Lato;
      font-size: 16px;
      letter-spacing: -0.05px;
      line-height: 19px;
  }
  .cancel {
    color: #039BE5;
    font-family: Lato;
    font-size: 16px;
    line-height: 24px;
    cursor: pointer;
  }

  .account-title{
    flex:1
  }
  .font-size-header{
    font-size:14px
  }
  @media (max-width: 767px) {
    .header-container{
        flex-direction: column-reverse;    
    }
    .font-size-header{
      font-size: 16px;
      margin-bottom: 0.5em;
    }
  }