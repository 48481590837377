.account-creation-n {
	height: 22px;
	width: 272px;
	color: #000000;
	font-family: Lato;
	font-size: 18px;
	font-weight: bold;
	letter-spacing: -0.05px;
	line-height: 22px;
}

.please-ensure-to-pro {
    height: 72px;
    width: 1118px;	
    color: #000000;	
    font-family: Lato;	
    font-size: 16px;	
    line-height: 24px;
  }

  .email-address {
    height: 19px;
    width: 99px;
    color: #000000;
    font-family: Lato;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: -0.05px;
    line-height: 19px;
  }

  .spiderman-webcrawler {
    height: 19px;
    width: 200px;
    color: #000000;
    font-family: Lato;
    font-size: 16px;
    letter-spacing: -0.05px;
    line-height: 19px;
  }

  .email-address-span {
      height: 19px;
      width: 329px;
  }

  .juniper-networks-use {
    height: 30px;
    width: 1022px;
    color: #000000;
    font-family: Lato;
    font-size: 12px;
    line-height: 40px;
  }

  .rectangle {
    height: 40px;
    width: 1120px;
    border: 1px solid #F2C074;
    background-color: #FFF4E4;
  }
  .email-address-copy-2 {
    height: 60px;
    width: 550px;
  }

  .email-address-copy-6 {
    height: 60px;
    width: 450px;
  }

  .email-address-copy-7 {
    height: 60px;
    width: 180px;
  }

  

  .email-address-copy-8 {
    height: 60px;
    width: 450px;
  }

  

  .email-address-copy-12 {
    height: 60px;
    width: 320px;
  }


  .email-address-copy-13 {
    height: 77px;
    width: 310px;
  }

  .email-address-copy-17 {
    height: 60px;
    width: 550px;
  }

  

  .rectangle-back-button {
    box-sizing: border-box;
    height: 37px;
    width: 98px;
    border: 1px solid #038BE5;
    background-color: #038BE5;
  }

  .back {
    height: 19px;
    width: 48px;
    color: #FFFFFF;
    font-family: Lato;
    font-size: 16px;
    letter-spacing: -0.05px;
    line-height: 19px;
  }

  .label-container{
    color: #000000;
    font-family: Lato;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: -0.05px;
    line-height: 19px;
  }
  .label-container-pre{
    color: #000000;
    font-family: Lato;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: -0.05px;
    line-height: 19px;
  }

  .account-title{
    flex:1
  }
  .font-size-header{
    font-size:14px
  }
  @media (max-width: 767px) {
    .header-container{
        flex-direction: column-reverse;    
    }
    .font-size-header{
      font-size: 16px;
      margin-bottom: 0.5em;
    }
    .prepopulated-container{
      padding: 1em;
      display: flex;
      flex-direction: column;
    }
    /* .label-container-pre{
      color: #000000;
      font-family: Lato;
      font-size: 16px;
      font-weight: bold;
      letter-spacing: -0.05px;
      line-height: 19px;
      display: flex;
      flex: 2 1;
    }
    .label-span-pre{
      display: flex;
      flex: 3;
    } */
    .prepopulated-fields{
      display: flex;
    }

}
  /*prepopulated fields container*/ 

  .prepopulated-container{
    background:#E0E0E0;
    padding: 1em;
    display: flex;
    flex-wrap: wrap;
  }

.prepopulated-fields {
  flex: 0 32%;
  margin-bottom: 2%; /* (100-32*3)/2 */
}
.label-span-pre{
  word-break: break-word;
} 
